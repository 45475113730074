const CAREER_TRACKS = require("../content/career-tracks.json")
const {
  getCareerTrackPrefixUrl,
  getSpecializationUrl,
} = require("../src/helpers/url")

const careerTracksMenu = CAREER_TRACKS.map(ct => {
  const specializations = ct.specializations.map(sp => {
    return Object.assign({}, sp, {
      to: getSpecializationUrl(ct.slug, sp.slug),
    })
  })

  return Object.assign({}, ct, {
    specializations,
    to: specializations[0].to,
    prefixTo: getCareerTrackPrefixUrl(ct.slug),
  })
})

module.exports = {
  // TODO: rename to "career-tracks"
  FIRST_LEVEL_MENU: careerTracksMenu,

  SECOND_LEVEL_MENU: [
    {
      name: "AD Government Grades",
      to: "/ad-government-grades",
    },
    {
      name: "Career Bands",
      to: "/career-bands",
    },
  ],
}
