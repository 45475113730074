import React from "react"
import styled from "styled-components"
import UnstyledLink from "@components/elements/UnstyledLink"

import MenuItem from "@components/elements/MenuItem"
import MobileMenu from "@components/modules/MobileMenu"
import Divider from "@components/elements/Divider"
import logo from "@assets/logo.svg"

import { FIRST_LEVEL_MENU, SECOND_LEVEL_MENU } from "@constants/menu"
import theme from "../assets/styles/theme"

const DesktopSideMenu = styled.div`
  min-width: 240px;
  width: 240px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.mainMenu};
  overflow-y: auto;
  justify-content: space-between;
  z-index: 2;
  @media (max-width: 1023px) {
    display: none;
  }
`

const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SideBarHeader = styled.div`
  display: inline-flex;
  justify-content: center;

  background: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  padding: 9px 0px;
  height: 78px; // for logo
`

const Menu = () => {
  return (
    <>
      <DesktopSideMenu>
        <SideBarContainer>
          <SideBarHeader>
            <UnstyledLink
              to="/"
              style={{ height: "auto", width: "195px", textAlign: "center" }}
            >
              <img
                src={logo}
                width="100%"
                height="100%"
                alt="Career Visualization Tool"
              />
            </UnstyledLink>
          </SideBarHeader>

          <MenuItem name="Career Navigator User Guide" to="/user-guide" />

          <Divider />

          <UnstyledLink
            style={{
              color: theme.colors.orange,
              fontSize: "12px",
              padding: "1rem",
            }}
            to="/"
          >
            Career Tracks
          </UnstyledLink>

          {FIRST_LEVEL_MENU.map((props, index) => (
            <MenuItem key={`first-menu-item-${index}`} {...props} />
          ))}

          <Divider />

          {SECOND_LEVEL_MENU.map((props, index) => (
            <MenuItem key={`second-menu-item-${index}`} {...props} />
          ))}
        </SideBarContainer>
      </DesktopSideMenu>
      <MobileMenu />
    </>
  )
}

export default Menu
