import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"

import { Box } from "@material-ui/core"

import theme from "@assets/styles/theme"
import UnstyledLink from "@components/elements/UnstyledLink"
import ChevronRight from "@material-ui/icons/ChevronRight"

const MenuItemContainer = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
`

const mainItemLinkStyle = {
  color: "black",
  textDecoration: "none",
  padding: "8px 16px",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "20px",
}

const activeLinkStyle = {
  background: theme.colors.subMenu,
  color: "white",
}

const MenuLink = props => {
  const { name, to, prefixTo, specializations = [], overrideStyle } = props

  const location = useLocation()
  const isMenuLinkActive = location.pathname.indexOf(prefixTo) !== -1
  const finalMainItemLinkStyle = isMenuLinkActive
    ? { ...mainItemLinkStyle, ...overrideStyle, ...activeLinkStyle }
    : { ...mainItemLinkStyle, ...overrideStyle }

  const renderSubMenuItems = subItems => {
    return (
      <Box style={isMenuLinkActive ? activeLinkStyle : {}}>
        {subItems.map((item, index) => {
          return (
            <UnstyledLink to={item.to} key={`sub-items-${index}`}>
              <Box
                display="flex"
                color="white"
                pl={1}
                pb={index === subItems.length - 1 ? 1 : 0}
                alignItems="center"
                index={`${name}-sub-items-${index}`}
              >
                <Box
                  component={ChevronRight}
                  fontSize="12px"
                  color={theme.colors.orange}
                />
                <Box pl={1} pr={2} py={1} fontSize="12px">
                  {item.name}
                </Box>
              </Box>
            </UnstyledLink>
          )
        })}
      </Box>
    )
  }

  return (
    <>
      {to ? (
        <UnstyledLink
          to={to}
          style={finalMainItemLinkStyle}
          activeStyle={activeLinkStyle}
          partiallyActive={true}
        >
          <MenuItemContainer>
            <span>{name}</span>
          </MenuItemContainer>
        </UnstyledLink>
      ) : (
        <MenuItemContainer style={mainItemLinkStyle}>
          <span>{name}</span>
        </MenuItemContainer>
      )}

      {isMenuLinkActive && specializations.length > 0
        ? renderSubMenuItems(specializations)
        : ""}
    </>
  )
}

export default MenuLink
