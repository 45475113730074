import flatten from "lodash/flatten"
import { getJobUrl, getSpecializationUrl } from "@helpers/url"

import JOB_DETAILS_DATA from "@content/job-details.out.json"
import CAREER_TRACKS from "@content/career-tracks.json"

const jobTitles = JOB_DETAILS_DATA.map(job => {
  return {
    name: job.jobTitle,
    to: getJobUrl(job.jobId),
    category: "Job",
    sector: job.sector,
    department: job.department,
    grade: job.grade,
  }
})

const specializations = flatten(
  CAREER_TRACKS.map(ct => {
    return ct.specializations.map(sp => {
      return Object.assign({}, sp, {
        to: getSpecializationUrl(ct.slug, sp.slug),
        category: "Specialization",
      })
    })
  })
)

export default [...jobTitles, ...specializations]
