import React from "react"
import styled, { ThemeProvider } from "styled-components"
import Menu from "@components/Menu"
import HeaderBar from "@components/elements/HeaderBar"
import theme from "@assets/styles/theme"
import { Helmet } from "react-helmet"
import { Box } from "@material-ui/core"

import { useLocation } from "@reach/router"

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const AppBodyWrapper = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`

const FlexGrow = styled.div`
  flex-grow: 1;
`

const FooterBox = styled(Box)`
  display: table;
  height: 40px;

  border-top: solid 1px #e8e8e8 !important;

  // above background image
  z-index: 2;

  .footer-content {
    display: table-cell;
    vertical-align: middle;

    text-align: right;

    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .footer-content.dark {
    color: #ffffff;
    background: ${props => props.theme.colors.subMenu};
  }

  .footer-content.light {
    color: ${props => props.theme.colors.textGrey};
    background: #ffffff;
  }
`

const AppLayout = ({ children, noHeader = false }) => {
  const location = useLocation()
  const footerClass = location.pathname === "/" ? "dark" : "light"

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Career Visualization Tool</title>
      </Helmet>
      <AppContainer>
        <Menu />
        <AppBodyWrapper>
          {noHeader ? <></> : <HeaderBar />}
          <FlexGrow>{children}</FlexGrow>
          <FooterBox>
            <Box px={4} className={`footer-content ${footerClass}`}>
              Copyright © 2022, Abu Dhabi Executive Office (ADEO) | Disclaimer -
              System is dynamic & updating
            </Box>
          </FooterBox>
        </AppBodyWrapper>
      </AppContainer>
    </ThemeProvider>
  )
}

export default AppLayout
