import React from "react"
import styled from "styled-components"

import BackButton from "@components/elements/BackButton"
import SearchTypeahead from "@components/modules/SearchTypeahead"

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px 24px;
  height: 78px;

  border-left: solid 1px #d8d8d8;
  border-bottom: solid 1px #d8d8d8;

  @media (max-width: 1023px) {
    display: none;
  }
`

const SearchWrapper = styled.div`
  flex: 1 0;
  padding: 12px 0 12px 24px;
`

const HeaderBar = () => {
  return (
    <Header>
      <BackButton width="36px" height="36px" />
      <SearchWrapper>
        <SearchTypeahead style={{ width: "100%" }} />
      </SearchWrapper>
      {/* <Button padding="12px 48px">Search</Button> */}
    </Header>
  )
}

export default HeaderBar
