/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import styled from "styled-components"
import MenuIcon from "@material-ui/icons/Menu"
import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"

import logo from "@assets/logo.svg"
import UnstyledLink from "@components/elements/UnstyledLink"
import MobileDivider from "@components/elements/MobileDivider"
import MobileMenuAccordion from "@components/modules/MobileMenuAccordion"

// TODO: remove
import { FIRST_LEVEL_MENU, SECOND_LEVEL_MENU } from "@constants/menu"
const JFG = []

const Menu = styled.div`
  position: sticky;
  top: 0;

  height: 40px;
  display: flex;

  color: ${props => (props.isOpen ? "white" : props.theme.colors.mainMenu)};
  background: ${props =>
    props.isOpen ? props.theme.colors.mainMenu : "white"};

  font-weight: bold;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  user-select: none;

  z-index: 3;

  > :last-child {
    margin-left: auto;
    cursor: pointer;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`

const MenuLink = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: ${({ margin }) => margin || "0px"};
  cursor: pointer;
  padding: 0.825rem 0px;
  user-select: none;

  > a {
    color: #2a2a2a;
  }
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const MenuModal = styled(Dialog)`
  @media (min-width: 1024px) {
    display: none;
  }
`

const ModalContent = styled.div`
  height: 100%;
  background: white;
  padding: 12px;
  font-size: 12px;
  color: #2a2a2a;
`

const SubTrackContainer = styled.div`
  background: rgba(214, 215, 215, 0.2);

  a {
    display: block;
    padding: 0.825rem;
    color: #2a2a2a;
  }
`

const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = React.useState(false)
  const [activeAccordionIndex, setActiveAccordionIndex] = React.useState(-1)

  const handleClose = () => setMenuOpen(false)

  return (
    <>
      <Menu>
        <UnstyledLink to="/" style={{ height: "16px", textAlign: "center" }}>
          <img
            src={logo}
            width="100%"
            height="16px"
            alt="Career Visualization Tool"
          />
        </UnstyledLink>
        <div onClick={() => setMenuOpen(true)}>
          <MenuIcon />
        </div>
      </Menu>
      <MenuModal
        fullScreen
        open={isMenuOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Menu style={{ borderBottom: "1px solid #979797" }} isOpen={isMenuOpen}>
          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </Menu>
        <ModalContent>
          <MenuLink>
            <UnstyledLink to="/career-philosophy" onClick={() => handleClose()}>
              Career Philosophy
            </UnstyledLink>
          </MenuLink>

          <MobileDivider margin="8px 0px" />

          <MenuLink style={{ fontSize: "10px", fontWeight: 100 }}>
            <UnstyledLink to="/" onClick={() => handleClose()}>
              Job Family Group
            </UnstyledLink>
          </MenuLink>

          {JFG.map((group, index) => (
            <MobileMenuAccordion
              header={group.name}
              index={index}
              active={activeAccordionIndex === index}
              setActiveIndex={() => setActiveAccordionIndex(index)}
              color={group.boxColor}
              key={index}
              hasSubTrack={group.jobFamilies.length > 0}
            >
              <SubTrackContainer>
                {group.jobFamilies.map((jf, jfIndex) => {
                  return (
                    <UnstyledLink
                      to={group.to}
                      onClick={() => handleClose()}
                      key={`accordion-${index}-${jfIndex}`}
                    >
                      {jf.name}
                    </UnstyledLink>
                  )
                })}
              </SubTrackContainer>
            </MobileMenuAccordion>
          ))}

          <MobileDivider margin="8px 0px" />

          {SECOND_LEVEL_MENU.map((menuItem, index) => (
            <div key={`m-2nd-menu-${index}`}>
              <MenuLink>
                <UnstyledLink to={menuItem.to} onClick={() => handleClose()}>
                  {menuItem.name}
                </UnstyledLink>
              </MenuLink>
            </div>
          ))}
        </ModalContent>
      </MenuModal>
    </>
  )
}

const EmptyMenu = () => {
  return <div />
}

export default EmptyMenu
