const theme = {
  colors: {
    // TODO: remove violet, daskViolet
    violet: "#702082",
    darkViolet: "#390A42",
    mainMenu: "#ECEFF1",
    subMenu: "#263238",
    orange: "#DC582A",
    textBlack: "#263238",
    textGrey: "#455A64",
    boxGreen: "#B3DED7",
    boxLightGreen: "#80C8BC",
    textLightGreen: "#148C8A",

    proficiencyTooltipBorder: "#ADC1D2",

    competencies: {
      core: {
        developing: { text: "#455A64", background: "#ECEFF1" },
        applying: { text: "#FFFFFF", background: "#8E989D" },
        leading: { text: "#FFFFFF", background: "#455A64" },
        shaping: { text: "#FFFFFF", background: "#263238" },
        default: { text: "#FFFFFF", background: "#263238" },
      },
      leadership: {
        developing: { text: "#002F6C", background: "#F2F5F8" },
        applying: { text: "", background: "#8194AC" },
        leading: { text: "#FFFFFF", background: "#002F6C" },
        shaping: { text: "#FFFFFF", background: "#000000" },
        default: { text: "#FFFFFF", background: "#000000" },
      },
      technical: {
        foundational: { text: "#455A64", background: "#F3FAF9" },
        intermediate: { text: "#263238", background: "#C9E7E3" },
        advanced: { text: "#263238", background: "#80C8BC" },
        expert: { text: "#FFFFFF", background: "#148C8A" },
        default: { text: "#FFFFFF", background: "#148C8A" },
      },
    },
  },
}

export default theme
