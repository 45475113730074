import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"

const BackButton = styled.div`
  margin: ${props => props.margin || ""};
  border: 1px solid #747c83;
  padding: 10px;
  width: ${props => props.width || "24px"};
  height: ${props => props.height || "24px"};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background: rgba(3, 3, 3, 0.1);
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const EnhancedBackButton = props => (
  <BackButton {...props} onClick={() => navigate(-1)}>
    <ChevronLeftIcon />
  </BackButton>
)

export default EnhancedBackButton
