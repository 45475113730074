// also update data-parsing/libs/constants

const GRADE_TEXT_MAPPING = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  "3_AND_4": "3&4",
  NO_DATA: "",
}

const COMPETENCY_TYPES = {
  CORE: "Core",
  TECHNICAL: "Technical",
  LEADERSHIP: "Leadership",
}

const LATERAL_MOVEMENT_PREFERENCES = {
  MOST_PREFERRED: "1",
  NORMAL_PREFERRED: "2",
  LEAST_PREFERRED: "3",
}

module.exports = {
  GRADE_TEXT_MAPPING,
  COMPETENCY_TYPES,
  LATERAL_MOVEMENT_PREFERENCES,
}
