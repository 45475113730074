module.exports = {
  getJobUrl: jobId => {
    return `/jobs/${jobId}`
  },
  getCareerTrackPrefixUrl: slug => {
    return `/career-tracks/${slug}`
  },
  getSpecializationUrl: (careerTrackSlug, specializationSlug) => {
    return `/career-tracks/${careerTrackSlug}/specializations/${specializationSlug}`
  },
  getCareerLevelUrl: careerLevelSlug => {
    return `/career-levels/${careerLevelSlug}`
  },
}
