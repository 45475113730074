import React from "react"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"

import Autocomplete from "@material-ui/lab/Autocomplete"
import searchIcon from "@assets/search.svg"
import searchData from "@constants/search"
import ListSubheader from "@material-ui/core/ListSubheader"
import { navigate } from "@reach/router"

import { GRADE_TEXT_MAPPING } from "@constants/index"
import { Box, Grid } from "@material-ui/core"

const SearchFlex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 8px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const TypeaheadInputWrapper = styled.div`
  width: 100%;
  > img.search-icon {
    position: absolute;
    padding: 8px;
    max-width: 36px;
    height: auto;

    color: ${props => props.theme.colors.mainMenu};
  }
`

const TypeaheadInput = styled.input`
  padding: 0 1rem;
  padding-left: 36px;
  /* width: calc(100% - 2rem); */
  width: 100%;
  height: 36px;
  border: none;
  background: ${props => (props.grey ? "#f5f7f8" : "white")};
`

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const renderGroup = params => [
  <ListSubheader
    key={params.key}
    style={{ background: "#f2f2f2" }}
    component="ul"
  >
    {params.group}
  </ListSubheader>,
  params.children,
]

// override padding
const useStyles = makeStyles({
  listbox: {
    margin: 0,
    padding: 0,
    overflow: "auto",
    listStyle: "none",
    maxHeight: "40vh",

    fontSize: "14px",
    fontFamily: "Arial",
  },
})

const SearchTypeahead = ({ style, grey = true }) => {
  // const [searchValue, setSearchValue] = React.useState("")
  const classes = useStyles()

  const handleSearchSelect = (event, value) => {
    // setSearchValue(value.name)
    navigate(`${value.to}/`)

    // if (value.category === "Job") {
    //   navigate(getJobUrl(value.jobId))
    // } else if (value.category === "Specialization") {
    //   navigate(getSpecializationUrl(value.slug))
    // }
  }

  const renderResultBox = (option, inputValue) => {
    const matches = match(option.name, inputValue)
    const parts = parse(option.name, matches)

    if (option.category === "Specialization") {
      return (
        <div>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </div>
      )
    }

    const { sector, department, grade } = option

    const firstLineText = [sector, department]
      .filter(s => typeof s !== "undefined" && s.length !== 0)
      .join(" | ")

    const gradeText = GRADE_TEXT_MAPPING[grade]
      ? `Grade ${GRADE_TEXT_MAPPING[grade]}`
      : ""

    return (
      <Grid container justifyContent="space-between">
        <Grid item>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </Grid>
        <Grid item>
          <FlexDiv>
            <Box>{firstLineText}</Box>
            <Box>{gradeText}</Box>
          </FlexDiv>
        </Grid>
      </Grid>
    )
  }

  return (
    <SearchFlex>
      <Autocomplete
        // freeSolo
        disableClearable
        options={searchData}
        getOptionLabel={option => option.name}
        onChange={handleSearchSelect}
        classes={classes}
        style={style}
        groupBy={option => option.category}
        renderGroup={renderGroup}
        renderOption={(option, { inputValue }) => {
          return renderResultBox(option, inputValue)
        }}
        renderInput={params => (
          <TypeaheadInputWrapper ref={params.InputProps.ref}>
            <img src={searchIcon} alt="" className="search-icon" />
            <TypeaheadInput
              type="text"
              grey={grey}
              {...params.inputProps}
              placeholder="Search Job Title / Specialization"
            />
          </TypeaheadInputWrapper>
        )}
      />
    </SearchFlex>
  )
}

export default SearchTypeahead
