import React from "react"
import styled from "styled-components"

const Divider = styled.div`
  height: 1px;
  width: 90%;
  background: #c6c8ca;
  margin: 8px 5%;
`

export default ({ margin }) => <Divider margin={margin} />
